import "./NumberLookupData.scss";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { JELookup } from "../../Model/JELookup";
import { DeleteJELookup, GetJELookup } from "../../services/NumberLookupData/NumberLookupDataService";
import Manufacturer from "../Manufacturer/Manufacturer";
import { JEDialog } from "../Wrappers/JEDialog";
import LoadingSpinner from "../Spinner/spinner";
import { NumberLookupDataForm } from "./NumberLookupDataForm";
import { ConfirmDeletePopUp } from "../ConfirmDeletePopUp/ConfirmDeletePopUp";
import { JETable, SortItems, SortRequestModel } from "../Wrappers/JETable";

interface NumberLookupDataInterface {
  handleAlert: (paget: string, type: string, msg: string, id: number) => any;
}
export default function NumberLookupData({handleAlert}: NumberLookupDataInterface) {
  const { getAccessToken } = useContext(AuthContext);

  //States
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<string>("AS");
  const [numRules, setNumRules] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [NumberLookupDataList, setnumberLookupDataList] = useState<JELookup[]>(
    []
  );
  const [openDialog, setDialogOpen] = useState(false);
  const [openDeleteDialog, setDeleteDialogOpen] = useState(false);
  const [jeLookupId, setjeLookupId] = useState<number>(0);

  //Event Handlers

  /** When the user change the seleccion (AS, QX and VX) */
  const airlineEventHandler = (data: string) => {
    console.info("New airline selected: " + data);
    setSelectedManufacturer(data);
  };

  /** A new record has been created */
  const addRuleEventHandler = (data: string) => {
    console.info("New NumberLookup Data added: " + data);
    setNumRules(numRules + 1);
    setDialogOpen(false);
    handleAlert("JE Number Lookup", "success", data, Math.random());
  };

  /** User clicks cancel button in the dialog window */
  const cancelRuleEventHandler = () => {
    console.info("New NumberLookup capture cancelled");
    setDeleteDialogOpen(false);
    setDialogOpen(false);
  };

  /**A record has been deleted*/
  const deleteRuleEventHandler = (data: string) => {
    console.info("NumberLookup deleted: " + data);
    setNumRules(numRules - 1);
    setDeleteDialogOpen(false);
    handleAlert("JE Number Lookup", "success", data, Math.random());
  };

  const errorRuleEventHandler = (data: string) => {
    setDialogOpen(false);
    setDeleteDialogOpen(false);
    handleAlert("JE Number Lookup", "error", data, Math.random());
  };

  /**User clicks on delete button*/
  const callConfirmDeletePopup = (jeLookupId: number) => {
    setjeLookupId(jeLookupId);
    setDeleteDialogOpen(true);
  };

  /** User clicks on the column to be sorted */
  const handleSortChange = (event: SortRequestModel) => {
    //Sort the table and set the state.
    const sortedTable = NumberLookupDataList.sort((a: any, b: any) =>
      SortItems(a, b, event)
    );
    setnumberLookupDataList(sortedTable);
  };

  const deleteNumberLookup = async (id:number, airline: string) => {
    DeleteJELookup(id, airline, getAccessToken())
        .then((response) => {
            if (response)
            {
              deleteRuleEventHandler("Successfully deleted Number Lookup Id: " + id + " for " + airline + ".");
            }
            else
            {
              errorRuleEventHandler("Failed deleting Number Lookup Id: " + id + " for " + airline + ". Message: API returned false.");
            }

        })
        .catch((error) => {
          errorRuleEventHandler("Error  while deleting Number Lookup Id : " + id + " for " + airline + "." + "Message: "+ error.message);
        });
  }  
  //Effects

  /** Refresh data */
  useEffect(() => {
    // declare the data fetching function
    async function fetchData () {

      // setIsLoading(true);
      // setnumberLookupDataList([]);
      // GetJELookup(selectedManufacturer, getAccessToken())
      // .then(result => {
      //   setnumberLookupDataList(result);
      //   setIsLoading(false);
      // })
      // .catch(error => {
      //   setIsLoading(false);
      //   console.log("error " + error);
      //   handleAlert("JE Filtration Rules", "error", error.message, Math.random())        
      // });
      try {
        setIsLoading(true);
        setnumberLookupDataList([]);
        const result = await GetJELookup(
          selectedManufacturer,
          getAccessToken()
        );
        setnumberLookupDataList(result);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        console.log("error " + e);
      }
    }

    fetchData();
    return () => {
      console.info("GetJELookup useEffect unmounted");
    };
  }, [selectedManufacturer, numRules]);

  //Render
  return (
    <>
      <div className="ComponentHeader">
        <div className="ComponentHeaderTitle">
          <auro-header>
            <span className="NumberLookupDataHeader">JE Number Lookup</span>
          </auro-header>
        </div>
        <div className="ComponentHeaderFilters">
          <Manufacturer handleEvent={airlineEventHandler} />
        </div>
        <div className="ComponentHeaderActions">
          <auro-button onClick={() => setDialogOpen(true)}>Add New</auro-button>
        </div>
      </div>
      <div className="ComponentContent">
        <div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <JETable
              columnsDefinition={[
                {
                  key: "Category",
                  name: "Category",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "CategoryCode",
                  name: "Category Code",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "JE_Number",
                  name: "JE Number",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "Description",
                  name: "Description",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "CreatedDate",
                  name: "Created Date (UTC)",
                  sortable: true,
                  dataFormat: "date",
                },
                {
                  key: "CreatedBy",
                  name: "Created By",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "JELookupId",
                  name: "JE Lookup ID",
                  sortable: true,
                  dataFormat: "string",
                },
              ]}
              tableData={NumberLookupDataList}
              keyFieldName="JELookupId"
              onSortChange={(event: SortRequestModel) =>
                handleSortChange(event)
              }
              onDeleteClick={(id: number) => callConfirmDeletePopup(id)}
            />
          )}
        </div>
      </div>
      <JEDialog
        open={openDialog}
        onclose={() => setDialogOpen(false)}
        header={"Add JE Number Lookup for " + selectedManufacturer}
        size="sm"
      >
        <NumberLookupDataForm
          manufacturer={selectedManufacturer}
          onInsert={(data: string) => addRuleEventHandler(data)}
          onCancel={() => cancelRuleEventHandler()}
          onError={(data: string) => errorRuleEventHandler(data)}
        />
      </JEDialog>
      <JEDialog
        open={openDeleteDialog}
        onclose={() => setDeleteDialogOpen(false)}
        header={"Delete Entry for " + selectedManufacturer}
        size="sm"
      >
        <ConfirmDeletePopUp
          manufacturer={selectedManufacturer}
          id={jeLookupId}
          text={"Do you want to delete JE Lookup ID : " + jeLookupId + " ?"}
          page="NumberLookupData"
          onDelete = {(id: number, airline: string) => deleteNumberLookup(id, airline) }
          onCancel={() => cancelRuleEventHandler()}

        />
      </JEDialog>
    </>
  );
}
