import { AuthConsumer } from "../../providers/AuthProvider";
import LoadingSpinner from "../Spinner/spinner";


function LogoutCallback() {
    return (
        <AuthConsumer>
            {({ signoutRedirectCallback }) => {
                signoutRedirectCallback();
                return <span><LoadingSpinner/></span>;
            }}
        </AuthConsumer>        
    );
}

export default LogoutCallback;
