import './notauthorized.css';

function NotAuthorized() {
    return (
     
<div id="notauthorized">
  <div className="notauthorized">
    <div className="notauthorized-401">
      <h1>401 - Unauthorized</h1>
      <h2>You are not authorized to access this application</h2>
    </div>
  </div>
</div>

    );
  }
  
  export default NotAuthorized;