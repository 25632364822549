import "./FiltrationRuleForm.scss";
import { useState, useEffect, useContext, FC } from "react";
import {
  GetSchemaItems,
  InsertFilterRule,
} from "../../services/FiltrationRules/FiltrationRulesService";
import { AuthContext } from "../../providers/AuthProvider";
import { SchemaItem } from "../../Model/SchemaItem";
import { FilterRuleModel } from "../../Model/FilterRuleModel";
import { ReactSVG } from "react-svg";

interface FiltrationRuleFormProps {
  manufacturer: string;
  onInsert: (data: string) => void;
  onCancel: () => void;
  onError: (data: string) => void;
}

export const FiltrationRuleForm: FC<FiltrationRuleFormProps> = ({
  manufacturer,
  onInsert,
  onCancel,
  onError,
}) => {
  //States
  const { getAccessToken } = useContext(AuthContext);
  const [filterRuleModel, setfilterRuleModel] = useState<FilterRuleModel>({
    SystemName: "JE",
    FilterRuleElements: [],
  });
  const [schemaItems, setSchemaItems] = useState<SchemaItem[]>([]);
  const [fieldName, setFieldName] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [description, setDescription] = useState("");
  const [busy, setBusy] = useState(false);

  //Effects
  // Call the GetSchemaItems api
  useEffect(() => {
    setBusy(true);
    GetSchemaItems(manufacturer, getAccessToken())
      .then((response) => {
        if (response === undefined || response.length === 0) {
          onError( "No schema items found for " + manufacturer);
        } else {
          setSchemaItems(response);
          setFieldName("");
        }
      })
      .finally(() => {
        setBusy(false);
      });
  }, [manufacturer]);

  //Functions
  const pushEntry = () => {
    const newFilterRuleEntry = [...filterRuleModel.FilterRuleElements];
    newFilterRuleEntry.push({
      FieldName: fieldName,
      FieldValue: fieldValue,
      Description: description,
    });
    setfilterRuleModel({
      SystemName: "JE",
      FilterRuleElements: newFilterRuleEntry,
    });
    //Clear form values
    setFieldName("");
    setFieldValue("");
    setDescription("");
  };

  const deleteEntry = (index: number) => {
    const newFilterRuleEntry = [...filterRuleModel.FilterRuleElements];
    newFilterRuleEntry.splice(index, 1);

    setfilterRuleModel({
      SystemName: "JE",
      FilterRuleElements: newFilterRuleEntry,
    });
  };

  const clearForm = () => {
    setfilterRuleModel({ SystemName: "JE", FilterRuleElements: [] });
    setFieldName("");
    setFieldValue("");
    setDescription("");
  };

  const clearAndCloseFilterRuleForm = () => {
    clearForm();
    onCancel();
  };

  const insertFilterRule = () => {
    setBusy(true);
    InsertFilterRule(manufacturer, filterRuleModel, getAccessToken())
      .then((response) => {
        clearForm();
        //Event needed to refresh the list of filtration rules
        onInsert(
          "Rule id " + response.RuleId + " was created for " + manufacturer
        );
      })
      .catch((error) => {
        onError("Error on save Filter Rule: " + error.message);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  //Render
  return (
    <div>
      <table className="auro_table">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Field Value</th>
            <th>Description</th>
            <th>
              <auro-button
                tertiary
                slim
                disabled={
                  busy || filterRuleModel.FilterRuleElements.length === 0
                    ? "disabled"
                    : undefined
                }
                onClick={() => clearForm()}
              >
                Clear all
              </auro-button>
            </th>
          </tr>
        </thead>
        <tbody>
          {filterRuleModel.FilterRuleElements.map((row, index) => (
            <tr key={index}>
              <td>{row.FieldName}</td>
              <td>{row.FieldValue}</td>
              <td>{row.Description}</td>
              <td className="centerColumn">
                <auro-button
                  role="button"
                  aria-label="deleteEntry"
                  tertiary
                  slim
                  iconOnly
                  onClick={() => deleteEntry(index)}
                >
                  <ReactSVG src="https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/x-stroke.svg" />
                </auro-button>
              </td>
            </tr>
          ))}
          <tr>
            <td>
            <select id="fieldNameSelect"
                className={fieldName === "" ? "placeholder" : ""}
                value={fieldName}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setFieldName(e.currentTarget.value)
                }
              >
                <option value="" disabled selected>
                  Please select an option
                </option>
                {schemaItems.map((row, index) =>
                  filterRuleModel.FilterRuleElements.find(
                    (x) => x.FieldName === row.ElementPath
                  ) === undefined ? (
                    <option
                      className="option"
                      key={index}
                      value={row.ElementPath}
                    >
                      {row.ElementName}
                    </option>
                  ) : null
                )}
              </select>
            </td>
            <td>              
              <auro-input id="fieldValue"                 
                bordered                
                role="input"
                aria-label="fieldValue"
                disabled={ busy ? "disabled" : undefined }       
                maxLength={200} 
                value={fieldValue} onInput={ (e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.currentTarget.value) }> 
                <span slot="label">Field Value</span>
                { <span className="inputCounter" slot="helptext">{fieldValue.length}/200</span> }
              </auro-input>
            </td>
            <td>
              <auro-input id="fiterRuleDescription"               
                bordered
                role="input"
                aria-label="fieldDescription"                
                disabled={ busy ? "disabled" : undefined }       
                maxLength={200} 
                value={description} onInput={ (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.currentTarget.value) }> 
                <span slot="label">Description</span>
                { <span className="inputCounter" slot="helptext">{description.length}/200</span> }
              </auro-input>
            </td>
            <td className="centerColumn">
              <auro-button
                disabled={busy || fieldName === "" ? "disabled" : undefined}
                role="button"
                aria-label="pushEntry"
                tertiary
                slim
                iconOnly
                onClick={() => pushEntry()}
              >
                <ReactSVG src="https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/plus-filled.svg" />
              </auro-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="formfooter">
        {/* <auro-popover for="btnCancel">
                    All changes will be lost. Are you sure you want to cancel? */}
        <auro-button
          id="btnCancel"
          slot="trigger"
          onClick={() => clearAndCloseFilterRuleForm()}
        >
          Cancel
        </auro-button>
        <auro-button
          disabled={
            busy || filterRuleModel.FilterRuleElements.length === 0
              ? "disabled"
              : undefined
          }
          secondary
          onClick={() => insertFilterRule()}
        >
          Save
        </auro-button>
      </div>
    </div>
  );
};
