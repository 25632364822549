/**
 * @description Returns the current window's base url.
 * @example https://aoc-test-frontdoor.azurefd.net, or https://localhost:3000
 * @return {string} - the base url
 */
export const getBaseUrl = () => {
    let uri = `${window.location.protocol}//${window.location.hostname}`;
    if (uri.includes("localhost")) {
      uri = `${uri}:${window.location.port}`;
    }
    return uri;
  };

