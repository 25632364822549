import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './providers/AuthProvider';
import './auro.scss';
import "@aurodesignsystem/auro-input";
import "@aurodesignsystem/auro-radio";
import "@aurodesignsystem/auro-header";
import "@aurodesignsystem/auro-button";
import "@aurodesignsystem/auro-background";
import "@aurodesignsystem/auro-icon";
import "@aurodesignsystem/auro-nav";
import "@aurodesignsystem/auro-table";
import "@aurodesignsystem/auro-skeleton";
import "@aurodesignsystem/auro-alert";
import "@alaskaairux/auro-popover";
import "@aurodesignsystem/auro-alert";

/*
  Declare global JSX elements: This is needed to avoid typescript errors when 
  using custom elements as Auro design system components.
*/
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "auro-header": any;
      "auro-radio": any;
      "auro-checkbox": any;
      "auro-button": any;
      "auro-input": any;
      "auro-radio-group": any;
      "auro-background": any;
      "auro-skeleton": any;
      "auro-icon":any;
      "auro-nav":any;
      "auro-anchorlink":any;
      "auro-table":any;
      "auro-dialog":any;
      "auro-popover":any; 
      "auro-alert":any;         
    }    
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//Every thing inside AuthProvider will have access to the auth context
root.render(
  <React.StrictMode>
    <AuthProvider>      
      <App />      
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
