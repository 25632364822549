import {Navigate, Route, Routes } from "react-router-dom";
import Callback from "../components/auth/Callback";
import Logout from "../components/auth/Logout";
import LogoutCallback from "../components/auth/LogoutCallback";
import SilentRenew from "../components/auth/SilentRenew";
import Menu from "../components/Menu/Menu";
import HomePage from "../components/HomePage/homePage";
import PingAuthRoute from "./PingAuthRoute";
import NotAuthorized from "../components/NotAuthorized/notauthorized";
import  FiltrationRules  from "../components/FiltrationRules/FiltrationRules";
import  NumberLookupData  from "../components/NumberLookupData/NumberLookupData";
import  FleetLookupData from "../components/FleetLookupData/FleetLookupData";
import GuardedRoute from "./GuardedRoute";
import { useEffect, useState } from "react";
import Alert from "../components/Alert/Alert";

/**
 * Application routes: Renders the menu and the components based on the route
 */

export default function AppRoutes() {
    const [msgid, setMsgId] = useState<number>(Math.random());
    const [alertType, setAlertType] = useState<string>("information");
    const [alertMsg, setAlertMessage] = useState<string>("M&E Trax Financial Interface initialized.");
    const [page, setPage] = useState<string>("Welcome");
    const alertHandler = (page: string, type: string, msg: string, id: number) => {
        setAlertType(type);
        setAlertMessage(msg);
        setPage(page);
        setMsgId(id);
      };
    useEffect(() => {
        console.info("Env " + process.env.REACT_APP_ENV);
    
    });  
    
    return (
        <>
            <Menu />
            <div className="ComponentContent">
                <Routes>
                    
                <Route path="/" element={<PingAuthRoute component={HomePage} path={undefined} />} />
  
                <Route path="/signin-oidc" element={<Callback/>} />
                    <Route element={<GuardedRoute />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/filtrationRules" element={<FiltrationRules handleAlert={alertHandler}/>} />
                        <Route path="/numberLookupData" element={<NumberLookupData handleAlert={alertHandler}/>} />
                        <Route path="/fleetLookupData" element={<FleetLookupData handleAlert={alertHandler}/>} />
                    </Route>
                    <Route path="/logout" element={<Logout/>} />
                    <Route path="/logout/callback" element={<LogoutCallback/>} />
                    <Route path="/silentrenew" element={<SilentRenew/>} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                    <Route path="/notauthorized" element={<NotAuthorized/>}/>
                </Routes>
            </div>    

            <div className="ComponentAlerts"><Alert page={page} message={alertMsg} type={alertType} messageid={msgid}></Alert></div>     
        </>
    );
}
