import { FC } from "react";

interface ConfirmDeletePopUpProps {
    manufacturer: string;
    id: number;
    text: string;
    page:string;
    onDelete: (id: number, data: string) => void;
    onCancel: () => void;  
}

export const ConfirmDeletePopUp: FC<ConfirmDeletePopUpProps> = ({ manufacturer,id,text,page, onDelete, onCancel }) => {


    const clearAndCloseConfirmDeletePopup = () => {
        onCancel();
    }
    const deleteHander = onDelete;
            
    //Render
    return(
        <div className="deletePopUp">            
           <span>{text}</span>
           <div className="formfooter">  
                    <auro-button id="btnCancel" slot="trigger"  onClick={ () => clearAndCloseConfirmDeletePopup()}>
                        No
                    </auro-button>
                <auro-button
                    secondary 
                    onClick={ () => deleteHander(id, manufacturer)}>
                        Yes
                </auro-button>
            </div>                      
        </div>
    )
}


