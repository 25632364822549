import { AuthConsumer } from "../../providers/AuthProvider";
import LoadingSpinner from "../Spinner/spinner";

function Logout () {
    return (
        <AuthConsumer>
            {({ logout }) => {
                logout();
                return <span><LoadingSpinner/></span>;
            }}
        </AuthConsumer>        
    );
}
export default Logout;
