import { useEffect, useState } from "react";
import "./Alert.scss";
import { ReactSVG } from "react-svg";

interface AlertProps {
    page:string
    message: string;
    type: string;
    messageid: number;

}

export default function Alert({page, message,messageid,type }: AlertProps) {
    const [show, setShow] = useState(true);

    useEffect(() => {
        console.log(message);
        const timeId = setTimeout(() => {
          setShow(false)
        }, 5000)
    
        return () => {
            setShow(true);
          clearTimeout(timeId);
        }
      }, [messageid]);

      const msg=()=>{
        return(
          <auro-alert className="AlertHeader" type={type}>
            <div className="AlertContent">
              <div className="AlertBody">
                <div>{page}</div>
                <div className="message">{message}</div>
              </div>
              <div className="AlertCloseIcon" role='button' aria-label='x-sm' onClick={ () => {setShow(false)}}>
                <ReactSVG src='https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/x-sm.svg'/>
              </div>              
            </div>                                               
          </auro-alert>
        )
      }
    
      // If show is false the component will return null and stop here
      if (!show) {
        return null;
      }

    return (
        <div>
            { msg() }
        </div>
    )
}


