import { AuthConsumer } from "../../providers/AuthProvider";
import LoadingSpinner from "../Spinner/spinner";

function Callback() {
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback();
        return <span><LoadingSpinner/></span>;
      }}
    </AuthConsumer>
  );
}
export default Callback;
