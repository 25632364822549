import { FC, useEffect, useState } from "react";
import "@alaskaairux/auro-interruption/dist/auro-dialog";
import "./JEDialog.scss";

interface JEDialogProps {
  open: boolean;
  header: string;
  children?: any;
  onclose: () => void;
  size: "lg" | "md" | "sm";
}

export const JEDialog: FC<JEDialogProps> = ({
  open,
  onclose,
  header,
  children,
  size,
}) => {
  //States
  const [openDialog, setOpen] = useState(false);

  const onPageLoad = () => {
    document.body.addEventListener("toggle", () => {
      setOpen(false);
      onclose && onclose();
    });
  };
  //Effects
  useEffect(() => {
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const renderLargeDialog = (
    <auro-dialog
      id="entryDialog"
      open={openDialog ? "open" : undefined}
      modal
      lg
    >
      <span slot="header">{header}</span>
      <span slot="content">{openDialog ? children : null}</span>
      <span slot="footer"></span>
    </auro-dialog>
  );

  const renderSmallDialog = (
    <auro-dialog
      id="entryDialog"
      open={openDialog ? "open" : undefined}
      modal
      sm
    >
      <span slot="header">{header}</span>
      <span slot="content">{openDialog ? children : null}</span>
      <span slot="footer"></span>
    </auro-dialog>
  );

  const renderMediumDialog = (
    <auro-dialog
      id="entryDialog"
      open={openDialog ? "open" : undefined}
      modal
      md
    >
      <span slot="header">{header}</span>
      <span slot="content">{openDialog ? children : null}</span>
      <span slot="footer"></span>
    </auro-dialog>
  );

  return (
    <div>
      {size === "lg"
        ? renderLargeDialog
        : size === "md"
        ? renderMediumDialog
        : renderSmallDialog}
    </div>
  );
};
