import { useState } from "react";
import "./Manufacturer.scss";
import React from "react";


interface ManufacturerProps {
  handleEvent: (data: string) => any;
}

export default function Manufacturer({ handleEvent }: ManufacturerProps) {
  const [selectedManufacturer, setSelectedManufacturer] = useState<string>("AS");

  // This function will be triggered when a radio button is selected
  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedManufacturer(event.target.value);
    handleEvent(selectedManufacturer);
  };

  return (
    <div>
      <fieldset className="radioFieldSet" role="radiogroup">
        <auro-radio-group horizontal required onClick={radioHandler}>
          <auro-radio
            role ="radio"
            aria-label="AS"
            id="AS"
            label="AS"
            name="AS"
            value="AS"
            checked
          ></auro-radio>
          <auro-radio
            role ="radio"
            aria-label="QX"
            id="QX"
            label="QX"
            name="QX"
            value="QX"
          ></auro-radio>
          <auro-radio
            role ="radio"
            aria-label="VX"          
            id="VX"
            label="VX"
            name="VX"
            value="VX"
          ></auro-radio>
        </auro-radio-group>
      </fieldset>
    </div>
  );
}
