import "./NumberLookupDataForm.scss";
import { useState, useContext, FC } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import "@alaskaairux/auro-button/dist/auro-button";
import "@alaskaairux/auro-popover/dist/auro-popover";
import { JELookupModel } from "../../Model/JELookupModel";
import { InsertJELookup } from "../../services/NumberLookupData/NumberLookupDataService";

interface NumberLookupDataFormProps {
    manufacturer: string;
    onInsert: (data: string) => void;
    onCancel: () => void;
    onError: (data: string) => void;
  }
export const NumberLookupDataForm: FC<NumberLookupDataFormProps>=({
    manufacturer,
    onInsert,
    onCancel,
    onError,
  })=>{
    const { getAccessToken } = useContext(AuthContext);
    const [Category, setcategory] = useState("");
    const [CategoryCode, setcategoryCode] = useState("");
    const [JE_Number, setjE_Number] = useState("");
    const[Description,setdescription]=useState("");    
    const[busy,setBusy]=useState(false);

    const cancelFilterRuleForm = () => {
      onCancel();
    };
    const insertjelookup = () => {
      setBusy(true);
      const JELookupModel: JELookupModel = {
        Category: Category,
        CategoryCode: CategoryCode,
        JE_Number: JE_Number,
        Description: Description
      };
    InsertJELookup(manufacturer, JELookupModel, getAccessToken())
      .then((response) => {
        console.info(response);
        //Clear all fields
        //clearForm(); Clear form is not needed and it was removed
        //Event needed to refresh the list of jelookup numbers
        if (response)
        {
          onInsert("JE Number Lookup record was created for " + manufacturer);          
        }
        else
        {
          onError("Error on save JE Number Lookup: " + response + ", Message: API returned false.")          
        }

      })
      .catch((error) => {
        onError("Error on save JE Number Lookup: " + error.message)
      })
      .finally(() => {
        setBusy(false);
      });
  };



//Render
return(
  <div>      
    <div className="NumberLookupFormField">
      <auro-input id="category" 
        required 
        bordered
        disabled={ busy ? "disabled" : undefined } 
        maxLength={100} 
        value={Category} 
        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setcategory(e.currentTarget.value)
            }> 
        <span slot="label">Category</span>
        { <span className="inputCounter" slot="helptext">{Category.length}/100</span> }
      </auro-input>
  </div>
  <div className="NumberLookupFormField">
    <auro-input id="categoryCode" 
      required
      bordered
      disabled={ busy ? "disabled" : undefined }       
      maxLength={100} 
      value={CategoryCode} onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setcategoryCode(e.currentTarget.value)
              }> 
      <span slot="label">Category Code</span>
      { <span className="inputCounter" slot="helptext">{CategoryCode.length}/100</span> }
    </auro-input>
  </div>
  <div className="NumberLookupFormField">
    <auro-input id="jeNumber" 
      required
      bordered
      disabled={ busy ? "disabled" : undefined } 
      maxLength={100} 
      value={JE_Number} 
      onInput={ (e: React.ChangeEvent<HTMLInputElement>) => setjE_Number(e.currentTarget.value) }> 
      <span slot="label">JE Number</span>
      { <span className="inputCounter" slot="helptext">{JE_Number.length}/100</span> }
    </auro-input>
  </div>
  <div className="NumberLookupFormField">
    <auro-input id="description" 
      bordered
      disabled={ busy ? "disabled" : undefined } 
      maxLength={200} 
      value={Description} 
      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setdescription(e.currentTarget.value)
            }> 
      <span slot="label">Description</span>
      { <span className="inputCounter" slot="helptext">{Description.length}/200</span> }
    </auro-input>
  </div>

<div className="NumberLookupFormFooter">
    {/* <auro-popover for="btnCancel">
                All changes will be lost. Are you sure you want to cancel? */}
    <auro-button
      disabled={
        busy
          ? "disabled"
          : undefined
      }
      id="btnCancel"
      slot="trigger"
      onClick={() => cancelFilterRuleForm()}>
      Cancel
    </auro-button>
    <auro-button
      
      disabled={
        busy || Category.trim()==="" || CategoryCode.trim()==="" || JE_Number.trim()===""
          ? "disabled"
          : undefined
      }
      secondary
      onClick={() => insertjelookup()}
    >
      Save
    </auro-button>
  </div>
</div>
)
};
