import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoutes from './routes/AppRoutes';
import './auro.scss';

export default function App() {

  useEffect(() => {
    console.info("Env " + process.env.REACT_APP_ENV);

    });  

  return (
    <>
      <BrowserRouter basename={"/"}>
        <AppRoutes/>
      </BrowserRouter>        
         
    </>
    

  );
}
