import "@aurodesignsystem/auro-header";
import "@alaskaairux/auro-button";
import "@aurodesignsystem/auro-input";
import "@aurodesignsystem/auro-background";
import "@alaskaairux/design-tokens/dist/tokens/CSSCustomProperties.css";
import "./Menu.scss";
import { ReactSVG } from "react-svg";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function Menu() {
  let userInitials="";
  let fetchedUsername = "";
  let validADGroup:any;
  let str = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_OIDC_PROVIDER}:${process.env.REACT_APP_OIDC_CLIENT_ID}`);
  
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [adGroup, setADGroup] = useState('');
  const popupRef = useRef<HTMLDivElement>(null);
  //const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // Clicked outside the popup, so close it
        setIsPopupOpen(false);
      }
    };

    // Attach the event listener to the document body
    document.addEventListener('click', handleClickOutside,true);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside,true);
    };
  }, [])
  
  if (str === null) {
    fetchedUsername = "Guest";
   // validADGroup=null;
  }
  else {
    const oidcStorage = JSON.parse(str);
  fetchedUsername = oidcStorage.profile.name;
   //fetchedUsername="Edgar Fuentes Flores"

   //get user initials
   const nameArray=fetchedUsername.split(' ');
   userInitials = nameArray.map(word=>word.charAt(0).toLocaleUpperCase()).join('');

   //validADGroup=null;
    console.log("user " + oidcStorage.profile.name)
  }

  //Functions
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  const closeMenu = () => {
    setNavbarOpen(false);
  };

  const handleIconClick = () => {
    setUsername(fetchedUsername);    
   validADGroup=sessionStorage.getItem("validADGroups");
    setADGroup(validADGroup);
    setIsPopupOpen(!isPopupOpen);
   // alert(isPopupOpen)
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
  };


  return (
    <div className="Titlebar">
      <div className="item1">
        <header className="header">
          <auro-header>
            <span className="HeaderClass">M&E Trax Financial Interface</span>
          </auro-header>
          <div className="top">
            <div className="menu_icon" onClick={handleToggle}>
              <ReactSVG src="https://unpkg.com/@alaskaairux/icons@latest/dist/icons/interface/navigation-menu.svg" />
            </div>
          </div>
        </header>
        <nav className={`menu ${navbarOpen ? "menu_show" : ""}`}>
          <Link to="/filtrationRules" className="item_menu" onClick={closeMenu}>
              JE Filtration Rules
          </Link>
          <Link
            to="/numberLookupData"
            className="item_menu"
            onClick={closeMenu}
          >
            JE Number Lookup
          </Link>
          <Link to="/fleetLookupData" className="item_menu" onClick={closeMenu}>
            Fleet Lookup
          </Link>
        </nav>
      </div>
      <div className="item2">
      <span className="userHeader" 
          onClick={handleIconClick}>
        {userInitials}
      </span>
      {isPopupOpen && (
        <div className="username-popup" ref={popupRef}>
        
          <div className="UserPopupBody">
            <span className="username">{username}</span>
            <span className="adgroup">{adGroup === null? "Guest": adGroup } </span> 
  </div>
  
</div>
      )}
      
    </div>
      
    </div>
  );
}
