import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getAdGroup } from "../services/AuthorizationService/AuthorizationService";

import { AuthConsumer } from "../providers/AuthProvider";
import NotAuthorized from "../components/NotAuthorized/notauthorized";
import LoadingSpinner from "../components/Spinner/spinner";

const GuardedRoute = () => {
  const [ADGroup, setADGroup] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      try {
        const result = await getAdGroup();
        setADGroup(result.ADGroup);
      } catch (e: any) {
        setADGroup("not authorized");
        console.log("error " + e);
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  });
  return (
    <AuthConsumer>
            {({ isAuthorized }) => {
                    if (ADGroup === null || ADGroup === undefined || ADGroup === "") {
                      return <span><LoadingSpinner /></span>
                    }
                    if (isAuthorized(ADGroup.trim())) {
                      console.log("adgroup is " + ADGroup);
                      return <Outlet />
                    }
                    else {
                      console.log("not authorized");
                      return <NotAuthorized />
                    }
            }
            }
        </AuthConsumer>     
    )
}
export default GuardedRoute;
