import { useContext, useEffect, useState } from "react";
import "./FleetLookupData.scss"
import { AuthContext } from "../../providers/AuthProvider";
import { FleetLookupModel } from '../../Model/FleetLookupModel';
import { DeleteFleetLookup, GetFleetLookup } from '../../services/FleetLookup/FleetLookupDataService';
import Manufacturer from "../Manufacturer/Manufacturer";
import { JEDialog } from "../Wrappers/JEDialog";
import LoadingSpinner from "../Spinner/spinner";
import { ConfirmDeletePopUp } from "../ConfirmDeletePopUp/ConfirmDeletePopUp";
import { FleetLookupDataForm } from "./FleetLookupDataForm";
import { JETable, SortItems, SortRequestModel } from "../Wrappers/JETable";

interface FleetLookupDataInterface {
    handleAlert: (paget:string, type: string, msg: string, id: number) => any;
}

export default function FleetLookupData({handleAlert}: FleetLookupDataInterface)  {
    const {getAccessToken}= useContext(AuthContext);

    //States
    const [selectedManufacturer, setSelectedManufacturer] = useState<string>("AS");
    const [numRules, setNumRules] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteDialog, setDeleteDialogOpen] = useState(false); 
    const [FleetLookupDataList ,SetFleetLookupDataList] = useState<FleetLookupModel[]>([]);
    const [openDialog, setDialogOpen] = useState(false); 
    const [fleetLookupId, setfleetLookupId] = useState<number>(0);

    //Event Handlers

    /** When the user change the seleccion (AS, QX and VX) */
    const airlineEventHandler = (data: string) => {
      console.info("New airline selected: " + data);
      setSelectedManufacturer(data);
    };

    /** A new record has been created */
    const addRuleEventHandler = (data: string) => {
        console.info("New FleetLookup Data added: " + data);        
        setNumRules(numRules + 1);
        setDialogOpen(false);
        handleAlert("Fleet Lookup", "success", data, Math.random())
    };
    

    /** User clicks cancel button in the dialog window */
    const cancelRuleEventHandler = () => {
        console.info("New FleetLookup capture cancelled");
        setDeleteDialogOpen(false);
        setDialogOpen(false);
    };

    /**A record has been deleted*/
    const deleteRuleEventHandler = (data: string) => {
        console.info("FleetLookup deleted: " + data);
        setNumRules(numRules - 1);
        setDeleteDialogOpen(false);
        handleAlert("Fleet Lookup", "success", data, Math.random())
    };
    
    const errorRuleEventHandler=(data:string)=>{

        setDialogOpen(false);
        setDeleteDialogOpen(false);
        handleAlert("Fleet Lookup", "error", data, Math.random())

    }
    const deleteFleetLookup = async (id:number, airline: string) => {
        DeleteFleetLookup(id, airline, getAccessToken())
            .then((response) => {
                if (response)
                {
                  deleteRuleEventHandler("Successfully deleted Fleet Lookup Id: " + id + " for " + airline + ".");
                }
                else
                {
                  errorRuleEventHandler("Failed deleting Fleet Lookup Id: " + id + " for " + airline + ". Message: API returned false.");
                }
    
            })
            .catch((error) => {
              errorRuleEventHandler("Error  while deleting Fleet Lookup Id: " + id + " for " + airline + "." + "Message: "+ error.message);
        });
      }
    /**User clicks on delete button*/
    const callConfirmDeletePopup=(fleetLookupId:number)=>{
        setfleetLookupId(fleetLookupId);
        setDeleteDialogOpen(true);
    };

    /** User clicks on the column to be sorted */
    const handleSortChange = (event: SortRequestModel) => {
        //Sort the table and set the state.
        const sortedTable = FleetLookupDataList.sort((a:any, b:any) => SortItems(a,b,event));
        SetFleetLookupDataList(sortedTable);
    };

    //Effects

    useEffect(() => {
    // declare the data fetching function
    async function fetchData() {
        // setIsLoading(true);
        // GetFleetLookup(selectedManufacturer, getAccessToken())
        // .then(result => 
        // {
        //     SetFleetLookupDataList(result); 
        //     setIsLoading(false);             
        // })
        // .catch(error =>
        // {
        //     setIsLoading(false);
        //     console.log("error " + error);
        //     handleAlert("JE Fleet Lookup", "error", error.message, Math.random())    
        // });
     

        try {
            setIsLoading(true);
            SetFleetLookupDataList([]); 
            var token =  getAccessToken();
            const result = await GetFleetLookup(selectedManufacturer,token);
            SetFleetLookupDataList(result); 
            setIsLoading(false);               
        }
        catch (e: any) {
            setIsLoading(false);
            console.log("error " + e);
        }
    }
            
    fetchData();
    return () => { 
        console.info("GetFleetLookup useEffect unmounted");
        }
    }, [selectedManufacturer, numRules]);
  
    //Render
    return (
    <>
        <div className="ComponentHeader">
            <div className="ComponentHeaderTitle">
                <auro-header>
                    <span className="FleetLookupDataHeader">
                        Fleet Lookup
                    </span>
                </auro-header>
            </div>
            <div className="ComponentHeaderFilters">
                <Manufacturer handleEvent={airlineEventHandler}/>
            </div>
            <div className="ComponentHeaderActions">
                <auro-button onClick={() => setDialogOpen(true)}>Add New</auro-button>
            </div>
        </div>            
        <div className="ComponentContent">
            <div>
                {isLoading ? 
                    <LoadingSpinner /> :
                    <JETable 
                        columnsDefinition = {
                        [
                            { key:"acseries", name: "ACSeries" , sortable: true, dataFormat: 'string' },
                            { key:"fleeT_NUMBER", name: "FleetNumber", sortable: true, dataFormat: 'string'},
                            { key:"description", name: "Description", sortable: true, dataFormat: 'string'},
                            { key:"createddate", name: "Created Date (UTC)", sortable: true, dataFormat: 'date'},
                            { key:"createdby", name: "Created By", sortable: true, dataFormat: 'string'},
                            { key:"fleetlookupid", name: "FleetLookupId", sortable: true, dataFormat: 'string'}
                        ]}
                        tableData = {FleetLookupDataList}
                        keyFieldName = "fleetlookupid"
                        onSortChange={(event: SortRequestModel) => handleSortChange(event) }
                        onDeleteClick={(id: number) => callConfirmDeletePopup(id)}/>
                }
            </div>
        </div> 
        <JEDialog
    open={openDialog}
    onclose={() => setDialogOpen(false)}
    header={"Add Fleet Lookup for " + selectedManufacturer} 
    size="sm"
    >
    <FleetLookupDataForm
        manufacturer={selectedManufacturer}
        onInsert={(data: string) => addRuleEventHandler(data)}
        onCancel={() => cancelRuleEventHandler()}
        onError={(data: string) => errorRuleEventHandler(data)}
    />
    </JEDialog>
        <JEDialog  
        open={openDeleteDialog}
        onclose={() => setDeleteDialogOpen(false)}
        header={"Delete Entry for " + selectedManufacturer}
        size="sm"
    >
    <ConfirmDeletePopUp
    manufacturer={selectedManufacturer}
        id={fleetLookupId}
        text={"Do you want to delete Fleet Lookup ID : " + fleetLookupId + " ?"}
        page="FleetLookup"
        onDelete = {(id: number, airline: string) => deleteFleetLookup(id, airline) }
        onCancel={ () => cancelRuleEventHandler() }/>
    </JEDialog>              
    </>
    );
};
    

