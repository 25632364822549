import "./homePage.scss";

/**
 * Home page component
 */
export default function HomePage() {
  
  return (
    <div className="HomePage">
      Welcome
    </div>
  );
}