import { FleetLookupModel } from "../../Model/FleetLookupModel";
import { FleetLookupRequest } from "../../Model/FleetLookupRequest";
import { checkStatus, parseJSON } from "../Common";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export async function GetFleetLookup(
  airline: string,
  token: string
): Promise<FleetLookupModel[]> {

  const url = `${apiUrl}FinancialInterfaceData/GetFleetLookup?airlineCode=${airline}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}

//delete fleet lookup
export async function DeleteFleetLookup(
  fleetLookupId: number,
  airline: string,
  token: string
): Promise<boolean> {
  const url = `${apiUrl}/FinancialInterfaceData/DeleteFleetLookup?lookupId=${fleetLookupId.toString()}&airlineCode=${airline}`;
  return await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}

export async function InsertFleetLookup(
  airline: string,
  fleetLookup: FleetLookupRequest,
  token: string
): Promise<boolean> {

  const url = `${apiUrl}FinancialInterfaceData/InsertFleetLookup?airlineCode=${airline}`;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(fleetLookup),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}
