import { JELookup } from "../../Model/JELookup";
import { JELookupModel } from "../../Model/JELookupModel";
import { checkStatus, parseJSON } from "../Common";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export async function GetJELookup(
  airline: string,
  token: string
): Promise<JELookup[]> {
  const url = `${apiUrl}/FinancialInterfaceData/GetJELookup?airlineCode=${airline}`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}

//InsertJELookup
export async function InsertJELookup(
  airline: string,
  jeLookup: JELookupModel,
  token: string
): Promise<boolean> {
  const url = `${apiUrl}FinancialInterfaceData/InsertJELookup?airlineCode=${airline}`;
  return await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(jeLookup),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}

//delete JE number lookup data
export async function DeleteJELookup(
  jeLookupId: number,
  airline: string,
  token: string
): Promise<boolean> {
  const url = `${apiUrl}FinancialInterfaceData/DeleteJELookup?lookupId=${jeLookupId.toString()}&airlineCode=${airline}`;
  return await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkStatus)
    //.then(parseJSON)
    .catch((error: any) => {
      console.log("error while calling the api ", error);
      throw new Error("API Call failed: " + error.message + "");
    });
}
