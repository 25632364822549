import "./FiltrationRules.scss";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { FilterRulesList } from "../../Model/FilterRulesList";
import {
  DeleteFiltrationRules,
  GetFiltrationRules,
} from "../../services/FiltrationRules/FiltrationRulesService";
import Manufacturer from "../Manufacturer/Manufacturer";
import { JEDialog } from "../Wrappers/JEDialog";
import { FiltrationRuleForm } from "./FiltrationRuleForm";
import LoadingSpinner from "../Spinner/spinner";
import { ConfirmDeletePopUp } from "../ConfirmDeletePopUp/ConfirmDeletePopUp";
import { JETable, SortItems, SortRequestModel } from "../Wrappers/JETable";

interface FiltrationRulesInterface {
  handleAlert: (page: string, type: string, msg: string, id: number) => any;
}
export default function FiltrationRules({
  handleAlert,
}: FiltrationRulesInterface) {
  const { getAccessToken } = useContext(AuthContext);

  //States
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<string>("AS");
  const [numRules, setNumRules] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterRulesList, setFilterRulesList] = useState<FilterRulesList[]>([]);
  const [openDialog, setDialogOpen] = useState(false);
  const [openDeleteDialog, setDeleteDialogOpen] = useState(false);
  const [filterRuleId, setFilterRuleId] = useState<number>(0);
  //Event Handlers

  /** When the user change the seleccion (AS, QX and VX) */
  const airlineEventHandler = (data: string) => {
    console.info("New airline selected: " + data);
    setSelectedManufacturer(data);
  };

  /** A new record has been created */
  const addRuleEventHandler = (data: string) => {
    console.info("New rule added: " + data);
    setNumRules(numRules + 1);
    setDialogOpen(false);
    handleAlert("JE Filtration Rules", "success", data, Math.random());
  };

  const errorRuleEventHandler = (data: string) => {
    setDialogOpen(false);
    setDeleteDialogOpen(false);
    handleAlert("JE Filtration Rules", "error", data, Math.random());
  };

  /** User clicks cancel button in the dialog window */
  const cancelRuleEventHandler = () => {
    console.info("New rule capture cancelled");
    setDeleteDialogOpen(false);
    setDialogOpen(false);
  };

  /**A record has been deleted*/
  const deleteRuleEventHandler = (data: string) => {
    //Display delete dialog
    console.info("rule deleted: " + data);
    setNumRules(numRules - 1);
    setDeleteDialogOpen(false);
    handleAlert("JE Filtration Rules", "success", data, Math.random());
  };

  /**User clicks on delete button*/
  const callConfirmDeletePopup = (filterrulid: number) => {
    //Display delete dialog
    setFilterRuleId(filterrulid);
    setDeleteDialogOpen(true);
  };

  /** User clicks on the column to be sorted */
  const handleSortChange = (event: SortRequestModel) => {
    //Sort the table and set the state.
    const sortedTable = filterRulesList.sort((a: any, b: any) =>
      SortItems(a, b, event)
    );
    setFilterRulesList(sortedTable);
  };
  const deleteFilterRules = async (id: number, airline: string) => {
    await DeleteFiltrationRules(id, airline, getAccessToken())
      .then((response) => {
        if (response) {
          deleteRuleEventHandler(
            "Successfully deleted Filter Rule Id: " +
              id +
              " for " +
              airline +
              "."
          );
        } else {
          errorRuleEventHandler(
            "Failed deleting Filter Rule Id: " +
              id +
              " for " +
              airline +
              ". Message: API returned false."
          );
        }
      })
      .catch((error) => {
        errorRuleEventHandler(
          "Error  while deleting Filter Rule: " +
            id +
            " for " +
            airline +
            "." +
            "Message: " +
            error.message
        );
      });
  };
  /** Refresh data */
  useEffect(() => {
    // declare the data fetching function
    async function fetchData() {
      setIsLoading(true);
      setFilterRulesList([]);
      await GetFiltrationRules(selectedManufacturer, getAccessToken())
        .then((result) => {
          setFilterRulesList(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("error " + error);
          handleAlert(
            "JE Filtration Rules",
            "error",
            error.message,
            Math.random()
          );
        });
      // try {
      //   setIsLoading(true);
      //   setFilterRulesList([]);
      //   const result = await GetFiltrationRules(
      //     selectedManufacturer,
      //     getAccessToken()
      //   );
      //     setFilterRulesList(result);
      //   setIsLoading(false);
      // } catch (e: any) {
      //   setIsLoading(false);
      //   console.log("error " + e);
      //   handleAlert("JE Filtration Rules", "error", e.message, Math.random())
      // }
    }

    fetchData();
    return () => {
      console.info("GetFiltrationRules useEffect unmounted");
    };
  }, [selectedManufacturer, numRules]);

  //Render
  return (
    <>
      <div className="ComponentHeader">
        <div className="ComponentHeaderTitle">
          <auro-header>
            <span className="FilterRulesHeader">JE Filtration Rules</span>
          </auro-header>
        </div>
        <div className="ComponentHeaderFilters">
          <Manufacturer handleEvent={airlineEventHandler} />
        </div>
        <div className="ComponentHeaderActions">
          <auro-button onClick={() => setDialogOpen(true)}>Add New</auro-button>
        </div>
      </div>
      <div className="ComponentContent">
        <div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <JETable
              columnsDefinition={[
                {
                  key: "RuleId",
                  name: "Rule ID",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "SystemName",
                  name: "System Name",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "FieldName",
                  name: "Field Name",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "FieldValue",
                  name: "Field Value",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "Description",
                  name: "Description",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "CreatedDate",
                  name: "Created Date (UTC)",
                  sortable: true,
                  dataFormat: "date",
                },
                {
                  key: "CreatedBy",
                  name: "Created By",
                  sortable: true,
                  dataFormat: "string",
                },
                {
                  key: "FilterRuleId",
                  name: "Filter Rule Id",
                  sortable: true,
                  dataFormat: "string",
                },
              ]}
              tableData={filterRulesList}
              keyFieldName="FilterRuleId"
              onSortChange={(event: SortRequestModel) =>
                handleSortChange(event)
              }
              onDeleteClick={(id: number) => callConfirmDeletePopup(id)}
            />
          )}
        </div>
      </div>
      <JEDialog
        open={openDialog}
        onclose={() => setDialogOpen(false)}
        header={"Add JE Filtration Rule for " + selectedManufacturer}
        size="lg"
      >
        <FiltrationRuleForm
          manufacturer={selectedManufacturer}
          onInsert={(data: string) => addRuleEventHandler(data)}
          onCancel={() => cancelRuleEventHandler()}
          onError={(data: string) => errorRuleEventHandler(data)}
        />
      </JEDialog>
      <JEDialog
        open={openDeleteDialog}
        onclose={() => setDeleteDialogOpen(false)}
        header={"Delete Entry for " + selectedManufacturer}
        size="sm"
      >
        <ConfirmDeletePopUp
          manufacturer={selectedManufacturer}
          id={filterRuleId}
          text={"Do you want to delete Filter Rule Id : " + filterRuleId + " ?"}
          page="FiltrationRules"
          onDelete={(id: number, airline: string) =>
            deleteFilterRules(id, airline)
          }
          onCancel={() => cancelRuleEventHandler()}
        />
      </JEDialog>
    </>
  );
}
