import React, {Component, useContext} from "react";
import AuthService from "../services/authService/authService";

/**
 * Authentication context type
 */
export type AuthContextType = {
    signinRedirectCallback: any,
    logout: any,
    signoutRedirectCallback: any,
    isAuthenticated: any,
    signinRedirect: any,
    signinSilentCallback: any,
    createSigninRequest: any,
    getAccessToken: any,
    isAuthorized: (ADGroup: string) => boolean,
}

/**
 * Authentication context
 */
export const AuthContext = React.createContext<AuthContextType>({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({}),
    getAccessToken: () => ({}),
    isAuthorized:(ADGroup: string)=> false
});

export const AuthConsumer = AuthContext.Consumer;
interface AuthProviderProps {
    children: React.ReactNode
}

/**
 Component that provides authentication context to all children
 **/
export class AuthProvider extends Component<AuthProviderProps> {
    
    //Private variables
    authService: AuthService;

    //constructor
    constructor(props: AuthProviderProps) {
        super(props);
        this.authService = new AuthService();
    }

    //Public methods

    ///Sign in redirect callback
    public getAccessToken = () => this.authService.getAccessToken();

    //Render
    render() {
        return <AuthContext.Provider value={{ 
            signinRedirectCallback: this.authService.signinRedirectCallback, 
            logout: this.authService.logout,
            signoutRedirectCallback: this.authService.signoutRedirectCallback,            
            signinRedirect: this.authService.signinRedirect,
            isAuthenticated: this.authService.isAuthenticated,
            signinSilentCallback: this.authService.signinSilentCallback,
            createSigninRequest: this.authService.createSigninRequest,
            getAccessToken: this.authService.getAccessToken,
            isAuthorized:this.authService.isAuthorized
        }}>{this.props.children}</AuthContext.Provider>;
    }
}

///Hook to use authentication context
export const useAuthentication = () => {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error("useAuthentication must be used within a AuthenticationProvider. Wrap a parent component in <AuthenticationProvider> to fix this error.")
    }
    return context;
}