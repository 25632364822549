export function translateStatusToErrorMessage(status: number) {
    switch (status) {
      case 401:
        return 'Status Code : 401 , Message : Please login again.';
      case 403:
        return 'Status Code : 401, Message : You do not have permission to view the project(s).';
        case 404:
          return 'Status Code : 404, Message : The requested resource could not be found.';
      case 500:
        return 'Status Code : 500, Message : Internal Server Error.';
        case 400:
          return 'Status Code : 400, Message : Bad Request.';
      default:
        return 'There was an error retrieving the project(s). Please try again.';
    }
  }
 export  function checkStatus(response: any) {
    if (response.ok) {
      return response;
    } else {
      const httpErrorInfo = {
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      };
      console.log(`log server http error: ${JSON.stringify(httpErrorInfo)}`);
  
      let errorMessage = translateStatusToErrorMessage(httpErrorInfo.status);
      throw new Error(errorMessage);
    }
  }
  
 export function parseJSON(response: Response) {
    console.log("response", response);
    return response.json();
  }