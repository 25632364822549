import { AuthConsumer } from "../../providers/AuthProvider";
import LoadingSpinner from "../Spinner/spinner";

function SilentRenew() {
  return (
    <AuthConsumer>
      {({ signinSilentCallback }) => {
        signinSilentCallback();
        return <span><LoadingSpinner/></span>;
      }}
    </AuthConsumer>
  );
}

export default SilentRenew;
