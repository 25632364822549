import HomePage from "../components/HomePage/homePage";
import LoadingSpinner from "../components/Spinner/spinner";
import { AuthConsumer } from "../providers/AuthProvider";

interface PrivateRouteProps {
    component: any
    path: any,
}
function PingAuthRoute( {component, ...path}: PrivateRouteProps) {
    return (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {
                if (isAuthenticated()) {
                    return <HomePage/>;
                } else {
                    signinRedirect();
                    return <span><LoadingSpinner/></span>;
                }
            }}
    </AuthConsumer>
    );
};

export default PingAuthRoute;