import { AuthorizationModel } from "../../Model/AuthorizationModel";
import { checkStatus, parseJSON } from "../Common";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export async function getAdGroup(): Promise<AuthorizationModel> {

  return await fetch(apiUrl + "Authorization/GetADGroup", {
    method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json'
      },
    })
  .then(checkStatus)
  .then(parseJSON)
  .catch((error: any) => {
    console.log("error while calling the api ", error);
    throw new Error("API Call failed: " + error.message +"");  
  });
}
    